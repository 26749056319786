/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General body styles */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Courier New", Courier, monospace;
  background-color: #e0e0e0; /* Light gray background */
  color: #000; /* Black text */
  overflow: hidden; /* Prevent body scroll */
  padding: 20px; /* Add padding to ensure margins around the container */
}

/* Container styles */
.app-container {
  text-align: center;
  background-color: #fff; /* White container */
  border: 4px solid #000; /* Black border */
  padding: 20px;
  box-shadow: 8px 8px 0 #000; /* Hard shadow */
  max-height: 90vh; /* Ensure container fits within the viewport */
  overflow: hidden; /* Prevent overflow in the container itself */
  margin: 0 20px; /* Add left and right margin */
}

/* Form styles */
.file-upload-container {
  margin-top: 20px;
  display: flex; /* Use flexbox for the form layout */
  flex-direction: column;
  align-items: center;
}

/* File input box styles */
.file-upload-container input[type="file"] {
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #000; /* Black border */
  border-radius: 5px;
  background-color: #ffd700; /* Bright yellow background */
  color: #000; /* Black text */
  font-weight: bold;
  width: 300px; /* Set width for the file input box */
}

/* Button styles */
.file-upload-container button {
  padding: 10px 20px;
  background-color: #ff4136; /* Bright red button */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.file-upload-container button:hover {
  background-color: #85144b; /* Darker red on hover */
}

/* Button for navigation */
.analytics-button {
  margin-top: 10px; /* Add top margin for spacing */
  padding: 10px 20px;
  background-color: #0074d9; /* Bright blue button */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  margin-bottom: 20px;
}

.analytics-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Table container styles */
.table-container {
  max-width: 100%;
  max-height: 70vh; /* Adjust this value to ensure it doesn't exceed the container height */
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Add this to your existing CSS file or style tag */

/* Table container styles */
.table-container {
  max-width: 100%;
  max-height: 70vh; /* Adjust this value to ensure it doesn't exceed the container height */
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Add this to your existing CSS file or style tag */

/* Table container styles */
.table-container {
  max-width: 100%;
  max-height: 70vh; /* Adjust this value to ensure it doesn't exceed the container height */
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Table styles */
.table {
  table-layout: auto; /* Let the table size itself based on content */
  border-collapse: collapse;
  width: 100%; /* Allow table to expand horizontally */
  font-size: 11px; /* Decrease the font size as needed */
}

.table th,
.table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.analytics-table {
  width: 100%; /* Full width */
  border-collapse: collapse;
  margin-top: 20px;
}

.analytics-table th,
.analytics-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.analytics-header {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.analytics-subheader {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
